
import React, { useContext, useEffect, useState } from 'react';
import Messages from './Messages';
import Input from './Input';
import { ChatContext } from '../context/ChatContext';
import io from 'socket.io-client';
import { AuthContext } from '../context/AuthContext';


// Create a socket instance
const socket = io('http://localhost:3001', {
  transports: ['websocket'],
});
console.log('Socket connected:', socket.connected);


// Listen for socket connection errors
{/*socket.on('connect_error', (error) => {
  console.log('Socket connection error:', error);
});*/}


const Chat = () => {
  const { data } = useContext(ChatContext);   // Get chat data from ChatContext
  const {currentUser} = useContext(AuthContext); // Get the current user from Firebase Auth


  useEffect(() => {
    // Listen for 'message' event from the socket
    socket.on('message', () => {
      console.log('The report was successfully received. If the user did upload a malicious file, he will be kicked out of the site!');
    });


    return () => {
      // Clean up the socket event listener when the component unmounts
      socket.off('message');
    };
  }, []);


  // Function to send a message via the socket
  const sendMessage = () => {
    const report = currentUser.displayName;
    const reported = data.user?.displayName;
    const message = `${report} is reporting: ${reported}`;
    socket.emit('message', message);
  };




  return (
    <div className='chat'>
      <div className='chatInfo'>
        <span>{data.user?.displayName}</span>
        <div className='chatIcons'>{/* Placeholder for chat icons */}</div>
      </div>
      <Messages />
      <Input />
      <button onClick={sendMessage}>Have you been sent a Malicious/suspicious file? Click here to report!</button>        
    </div>
  );
};


export default Chat;


