import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../firebase';

const Login = () => {

  const [err, setErr] = useState(false); // Used to set the error message state to false initially,--
                                          //--and to set the error message to a string if an error occurs during the login process.

  const navigate = useNavigate()


  {/*Called when the 'login' button is clicked. It takes an event object and extracts the email and password from the input fields.*/}

  const handleSubmit = async (e)=>{
    e.preventDefault();
    const email = e.target[0].value;
    const password = e.target[1].value;

    if (!email || !password) {
      setErr({ message: 'All fields are required.' });
    } else {
    try{
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/")
    } catch (err){
        setErr({ message: err.message.slice(9) });
    }
  }
  };


  return (
    <div className='formContainer'>
      <div className='formWrapper'>
        <div className="logoContainer">
          <span className="logo">YK</span>
          <span className="subLogo">FileShare</span>
        </div>
        <h1 className="title">Login</h1>
        <form onSubmit={handleSubmit}>
            <input type="email" placeholder="Email" />
            <input type="password" placeholder="Password" />
          <button>Sign In</button>
          {err && <span className="error-message">{err.message}</span>}
        </form>
        <p className="registerLink">Don't have an account? <Link to="/register">Register</Link></p>
      </div>
    </div>
  );
};

export default Login