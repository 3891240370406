import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { ChatContext } from '../context/ChatContext';

const Message = ({ message }) => {
  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);
  const ref = useRef();
  const [fileName, setFileName] = useState(message.fileName);
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  {/*Get the sender's profile picture, if any, else the default one.*/}
  const isCurrentUser = message.senderId === currentUser.uid;

  const renderMessageContent = () => {
    if (message.fileUrl) {
      return (
        <div>
          <p>File: {fileName}</p>
          <a href={message.fileUrl} target="_blank" rel="noopener noreferrer">Download</a>
        </div>
      );
    }
    return null;
  };

  return (
    <div ref={ref} className={`message ${isCurrentUser ? "owner" : ""}`}>
      <div className="messageInfo">
        <img
          src={isCurrentUser ? currentUser.photoURL : data.user.photoURL}
          alt="Profile"
        />
        <span></span>
      </div>
      <div className="messageContent">
        {renderMessageContent()}
      </div>
    </div>
  );
};

export default Message;
