import React, { useContext, useState } from "react";
import attach from "../img/attach.png";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
import {
  arrayUnion,
  doc,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../firebase";
import { v4 as uuid } from "uuid";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  getMetadata,
} from "firebase/storage";

const Input = () => {
  // Set up state for the text input and file
  const [text, setText] = useState("");
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);

  // Handle sending a message (with or without a file)
  const handleSend = async () => {
    if (!file) return;

    try {
        const storageRef = ref(storage, uuid());
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          null,
          (error) => {
            console.error(error);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              const metadata = await getMetadata(uploadTask.snapshot.ref);
              const fileName = file.name;
              const fileSize = metadata.size;

              await updateDoc(doc(db, "chats", data.chatId), {
                messages: arrayUnion({
                  id: uuid(),
                  text,
                  senderId: currentUser.uid,
                  date: Timestamp.now(),
                  fileUrl: downloadURL,
                  fileName,
                  fileSize,
                }),
              });

              // Update the last message and date in the userChats document for both the current user and the chat partner
              await updateDoc(doc(db, "userChats", currentUser.uid), {
                [data.chatId + ".lastMessage"]: {
                  text,
                  fileUrl: downloadURL,
                  fileName,
                  fileSize,
                },
                [data.chatId + ".date"]: serverTimestamp(),
              });

              await updateDoc(doc(db, "userChats", data.user.uid), {
                [data.chatId + ".lastMessage"]: {
                  text,
                  fileUrl: downloadURL,
                  fileName,
                  fileSize,
                },
                [data.chatId + ".date"]: serverTimestamp(),
              });
              // Clear the input fields after sending
              setText("");
              setFile(null);
              setFileUrl(null);
            } catch (error) {
              console.error(error);
            }
          }
        );
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileUrl(URL.createObjectURL(selectedFile));
    }
  };

  return (
    <div className="input">
      <div className="send">
        <input
          type="file"
          style={{ display: "none" }}
          id="file"
          onChange={handleFileChange}
        />
        <label htmlFor="file">
          <img src={attach} alt="" />
        </label>
        {fileUrl && (
          <span>
            {file.name} ({file.size} bytes)
          </span>
        )}
        <button onClick={handleSend}>Send</button>
      </div>
    </div>
  );
};

export default Input;